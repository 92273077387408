"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _mongoObject = _interopRequireDefault(require("mongo-object"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

/**
 * Clones a schema object, expanding shorthand as it does it.
 */
function expandShorthand(schema) {
  const schemaClone = {};
  Object.keys(schema).forEach(key => {
    const definition = schema[key]; // CASE 1: Not shorthand. Just clone

    if (_mongoObject.default.isBasicObject(definition)) {
      schemaClone[key] = _objectSpread({}, definition);
      return;
    } // CASE 2: The definition is an array of some type


    if (Array.isArray(definition)) {
      if (Array.isArray(definition[0])) {
        throw new Error(`Array shorthand may only be used to one level of depth (${key})`);
      }

      const type = definition[0];
      schemaClone[key] = {
        type: Array
      }; // Also add the item key definition

      const itemKey = `${key}.$`;

      if (schema[itemKey]) {
        throw new Error(`Array shorthand used for ${key} field but ${key}.$ key is already in the schema`);
      }

      if (type instanceof RegExp) {
        schemaClone[itemKey] = {
          type: String,
          regEx: type
        };
      } else {
        schemaClone[itemKey] = {
          type
        };
      }

      return;
    } // CASE 3: The definition is a regular expression


    if (definition instanceof RegExp) {
      schemaClone[key] = {
        type: String,
        regEx: definition
      };
      return;
    } // CASE 4: The definition is something, a type


    schemaClone[key] = {
      type: definition
    };
  });
  return schemaClone;
}

var _default = expandShorthand;
exports.default = _default;
module.exports = exports.default;
module.exports.default = exports.default;