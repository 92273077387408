"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _clone = _interopRequireDefault(require("clone"));

var _lodash = _interopRequireDefault(require("lodash.includes"));

var _utility = require("../utility");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function getFieldInfo(mongoObject, key) {
  const keyInfo = mongoObject.getInfoForKey(key) || {};
  return {
    isSet: keyInfo.value !== undefined,
    value: keyInfo.value,
    operator: keyInfo.operator || null
  };
}

class AutoValueRunner {
  constructor(options) {
    this.options = options;
    this.doneKeys = [];
  }

  runForPosition({
    key: affectedKey,
    operator,
    position,
    value
  }) {
    const {
      closestSubschemaFieldName,
      extendedAutoValueContext,
      func,
      isModifier,
      isUpsert,
      mongoObject
    } = this.options; // If already called for this key, skip it

    if ((0, _lodash.default)(this.doneKeys, affectedKey)) return;
    const fieldParentName = (0, _utility.getParentOfKey)(affectedKey, true);
    const parentFieldInfo = getFieldInfo(mongoObject, fieldParentName.slice(0, -1));
    let doUnset = false;

    if (Array.isArray(parentFieldInfo.value)) {
      if (isNaN(affectedKey.split('.').slice(-1).pop())) {
        // parent is an array, but the key to be set is not an integer (see issue #80)
        return;
      }
    }

    const autoValue = func.call(_objectSpread({
      closestSubschemaFieldName: closestSubschemaFieldName.length ? closestSubschemaFieldName : null,

      field(fName) {
        return getFieldInfo(mongoObject, closestSubschemaFieldName + fName);
      },

      isModifier,
      isUpsert,
      isSet: value !== undefined,
      key: affectedKey,
      operator,

      parentField() {
        return parentFieldInfo;
      },

      siblingField(fName) {
        return getFieldInfo(mongoObject, fieldParentName + fName);
      },

      unset() {
        doUnset = true;
      },

      value
    }, extendedAutoValueContext || {}), mongoObject.getObject()); // Update tracking of which keys we've run autovalue for

    this.doneKeys.push(affectedKey);
    if (doUnset && position) mongoObject.removeValueForPosition(position);
    if (autoValue === undefined) return; // If the user's auto value is of the pseudo-modifier format, parse it
    // into operator and value.

    if (isModifier) {
      let op;
      let newValue;

      if (autoValue && typeof autoValue === 'object') {
        const avOperator = Object.keys(autoValue).find(avProp => avProp.substring(0, 1) === '$');

        if (avOperator) {
          op = avOperator;
          newValue = autoValue[avOperator];
        }
      } // Add $set for updates and upserts if necessary. Keep this
      // above the "if (op)" block below since we might change op
      // in this line.


      if (!op && position.slice(0, 1) !== '$') {
        op = '$set';
        newValue = autoValue;
      }

      if (op) {
        // Update/change value
        mongoObject.removeValueForPosition(position);
        mongoObject.setValueForPosition(`${op}[${affectedKey}]`, (0, _clone.default)(newValue));
        return;
      }
    } // Update/change value. Cloning is necessary in case it's an object, because
    // if we later set some keys within it, they'd be set on the original object, too.


    mongoObject.setValueForPosition(position, (0, _clone.default)(autoValue));
  }

}

exports.default = AutoValueRunner;
module.exports = exports.default;
module.exports.default = exports.default;