"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _mongoObject = _interopRequireDefault(require("mongo-object"));

var _extend = _interopRequireDefault(require("extend"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

class SimpleSchemaGroup {
  constructor(...definitions) {
    this.definitions = definitions.map(definition => {
      if (_mongoObject.default.isBasicObject(definition)) return (0, _extend.default)(true, {}, definition);

      if (definition instanceof RegExp) {
        return {
          type: String,
          regEx: definition
        };
      }

      return {
        type: definition
      };
    });
  }

  get singleType() {
    return this.definitions[0].type;
  }

  clone() {
    return new SimpleSchemaGroup(...this.definitions);
  }

  extend(otherGroup) {
    // We extend based on index being the same. No better way I can think of at the moment.
    this.definitions = this.definitions.map((def, index) => {
      const otherDef = otherGroup.definitions[index];
      if (!otherDef) return def;
      return (0, _extend.default)(true, {}, def, otherDef);
    });
  }

}

var _default = SimpleSchemaGroup;
exports.default = _default;
module.exports = exports.default;
module.exports.default = exports.default;